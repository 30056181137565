import { IAnalyticsContext } from '@sennder/senn-node-microfrontend-interfaces'
import { AnalyticsProvider } from '@sennder/shell-utilities'

import { logger } from '@/services/logger/loggers'

export const DEFAULT_PREFIX = 'octopus'

/**
 * This is a temporary solution to fix the event prefix name issue.
 * Ideally each microfrontend should not define the prefix name, but should be defined in the shell.
 */
const getTrackEventName = (name: string): string => {
  // If events came from orcas
  if (name.startsWith('orcas/')) {
    return name.replace('orcas/', `${DEFAULT_PREFIX}/`)
  }

  // If events has no prefix
  if (!name.startsWith(`${DEFAULT_PREFIX}/`)) {
    return `${DEFAULT_PREFIX}/${name}`.replace(/\/\//g, '/')
  }

  return name
}

export class AppAnalyticsProvider extends AnalyticsProvider {
  constructor(context: IAnalyticsContext) {
    super({
      context,
      logger,
      messageSource: DEFAULT_PREFIX,
      formatEventName: getTrackEventName,
    })
  }
}

export const analytics = new AppAnalyticsProvider({
  module: 'tms-shell',
  submodule: '',
})
